var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-create-update-reason",attrs:{"title":((_vm.reason == null ? 'Registrar' : 'Actualizar') + " motivo"),"cancel-title":"Cancelar","size":"md"},on:{"hidden":function($event){return _vm.$emit('hidden', false)}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [(!_vm.loading)?_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancelar ")]):_c('b-button',{attrs:{"variant":"primary","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Cargando... ")],1),(!_vm.loading)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.onSave()}}},[_vm._v(" "+_vm._s(_vm.btnSaveTitle)+" ")]):_c('b-button',{attrs:{"variant":"primary","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Cargando... ")],1)]}}])},[_c('ValidationObserver',{ref:"form"},[_c('b-form-group',{attrs:{"id":"input-group-3","label":"Modulo:","label-for":"input-3"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"input-3","options":_vm.arrayModules,"value-field":"id","text-field":"name","disabled":_vm.reason != null,"state":errors[0] ? false : null},model:{value:(_vm.reasonObject.module_id),callback:function ($$v) {_vm.$set(_vm.reasonObject, "module_id", $$v)},expression:"reasonObject.module_id"}})]}}])})],1),_c('b-form-group',{attrs:{"id":"input-group-1","label":"Razón:","label-for":"input-1"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-1","type":"text","required":"","state":errors[0] ? false : null},model:{value:(_vm.reasonObject.reason),callback:function ($$v) {_vm.$set(_vm.reasonObject, "reason", $$v)},expression:"reasonObject.reason"}})]}}])})],1),(_vm.arrayReasons.length)?[_c('b-form-group',{attrs:{"id":"input-group-4"}},[_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1","disabled":_vm.reasonObject.module_id == null && _vm.reasonObject.reason == null},model:{value:(_vm.reasonObject.isSubOption),callback:function ($$v) {_vm.$set(_vm.reasonObject, "isSubOption", $$v)},expression:"reasonObject.isSubOption"}},[_vm._v(" Es un sub-motivo ")])],1),(_vm.reasonObject.isSubOption)?_c('b-form-group',{attrs:{"id":"input-group-3","label":"Sub-motivo:","label-for":"input-3"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"input-3","options":_vm.arrayReasons,"value-field":"id","text-field":"reason","state":errors[0] ? false : null},model:{value:(_vm.reasonObject.parent_id),callback:function ($$v) {_vm.$set(_vm.reasonObject, "parent_id", $$v)},expression:"reasonObject.parent_id"}})]}}],null,false,4167933494)})],1):_vm._e()]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }