<template>
	<div>
		<b-card no-body class="mb-1">
			<div class="m-2">
				<div class="mx-2 mb-2">
					<b-row>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start"
						>
							<span class="text-muted"
								>Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros</span
							>
						</b-col>
						<!-- Pagination -->
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>
							<b-pagination
								v-model="current_page"
								:total-rows="total_data"
								:per-page="perpage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon icon="ChevronLeftIcon" size="18" />
								</template>
								<template #next-text>
									<feather-icon icon="ChevronRightIcon" size="18" />
								</template>
							</b-pagination>
						</b-col>
					</b-row>
				</div>
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->

					<b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perpage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label class="mr-2">registros</label>
						<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="resetSearch" />
					</b-col>
					<!-- Search -->
					<b-col cols="12" md="6">
						<div class="d-flex align-items-center justify-content-end">
							<b-form-input
								v-model="search_input"
								class="d-inline-block mr-1"
								placeholder="Motivo..."
								debounce="500"
							/>
							<b-button variant="primary" @click="openRegisterReason" v-if="isAdmin">
								<span class="text-nowrap">Agregar motivo</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>
			<div class="table-responsive">
				<b-table
					:api-url="'/api/reasons'"
					ref="refReasonsList"
					class="position-relative"
					:items="myProvider"
					:stacked="currentBreakPoint === 'sm' || currentBreakPoint === 'xs'"
					sticky-header="75vh"
					:fields="arrayColumns"
					primary-key="id"
					table-class="text-nowrap"
					responsive
					show-empty
					:busy.sync="isBusy"
					empty-filtered-text="No hay registros que coincidan con tu búsqueda."
					empty-text="No hay registros que mostrar."
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
					:current-page="current_page"
					:per-page="perpage"
					:filter="search_input"
				>
					<template #table-busy>
						<div class="text-center text-primary my-2">
							<b-spinner class="align-middle"></b-spinner>
							<strong>Cargando...</strong>
						</div>
					</template>
					<template #cell(created_at)="data">
						{{ data.item.first_name_created_by }}
						{{ data.item.last_name_created_by }} <br />
						{{ data.item.created_at | myGlobalDayWhour }}
					</template>
					<template #cell(parent_reason)="data">
						{{ data.item.parent_reason ? data.item.parent_reason.toUpperCase() : "" }}
					</template>
					<template #cell(reason)="data">
						{{ data.item.parent_reason ? data.item.reason.toLowerCase() : data.item.reason.toUpperCase() }}
					</template>
					<template #cell(actions)="data">
						<feather-icon
							icon="EditIcon"
							class="cursor-pointer mr-1 text-info"
							size="20"
							@click="editReason(data.item)"
							:id="`${data.index}-edit-icon`"
							v-if="isAdmin"
						/>
						<b-tooltip title="Editar" :target="`${data.index}-edit-icon`" />
						<feather-icon
							icon="TrashIcon"
							class="cursor-pointer text-danger"
							size="20"
							@click="deleteReason(data.item)"
							:id="`${data.index}-trash-icon`"
							v-if="isAdmin"
						/>
						<b-tooltip title="Eliminar" :target="`${data.index}-trash-icon`" />
					</template>
				</b-table>
			</div>
			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted"
							>Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros</span
						>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="current_page"
							:total-rows="total_data"
							:per-page="perpage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<!-- <b-modal
			v-model="controllerCreateReason"
			size="lg"
			:title="`${selectedReason ? 'Actualizar' : 'Registrar'} motivo`"
			hide-footer
			scrollable
		> -->
		<CreateReason v-if="controllerCreateReason" :reason="selectedReason" @hidden="closeCreateReason" />
	</div>
</template>
<script>
import vSelect from "vue-select";
import axios from "@/axios";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import CreateReason from "./CreateReason.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import reasonsService from "@/services/reasons.service";
export default {
	directives: {
		Ripple,
	},
	components: {
		vSelect,
		AppCollapse,
		AppCollapseItem,
		CreateReason,
	},
	data() {
		return {
			sortBy: "created_at",
			sortDesc: true,
			arrayColumns: [
				{
					key: "module_name",
					label: "Módulo",
					class: "text-center column-table",
					sortable: true,
				},
				{
					key: "reason",
					label: "Motivo",
					class: "text-center",
					sortable: true,
				},
				{
					key: "parent_reason",
					label: "Motivo padre",
					class: "text-center",
					sortable: true,
				},
				{
					key: "created_at",
					label: "Creado por",
					class: "text-center ",
					sortable: true,
				},
				{ key: "actions", label: "Acciones", class: "text-center " },
			],
			search_input: "",
			orderby: this.dato2 == null ? 3 : this.dato2,
			order: this.dato1 == null ? "desc" : this.dato1,
			start_page: "",
			end_page: "",
			total_data: "",
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: "",
			dato1: "",
			dato2: "",
			loadingData: false,
			isBusy: false,
			perPageOptions: [10, 25, 50, 100],
			chickenleg: null,
			loading: false,
			createDeadsPageController: false,
			deadSpecimenDetail: false,
			selectedDeadSpecimenId: null,
			show: false,
			controllerCreateReason: false,
			selectedReason: {},
		};
	},
	methods: {
		onChangeFilter() {
			this.$refs.refReasonsList.refresh();
		},
		myProvider(ctx) {
			const promise = axios.get(
				// "/some/url?page=" + ctx.currentPage + "&size=" + ctx.perPage
				`${ctx.apiUrl}?page=${ctx.currentPage}`,
				{
					params: {
						type_specimen: 1,
						orderby: ctx.sortDesc == 1 ? "desc" : "asc",
						order: ctx.sortBy == "" ? "created_at" : ctx.sortBy,
						campo: ctx.filter,
						perpage: ctx.perPage,
					},
				}
			);

			// Must return a promise that resolves to an array of items
			return promise.then((data) => {
				// Pluck the array of items off our axios response
				const items = data.data.data;
				this.start_page = data.data.from;
				this.current_page = data.data.current_page;
				this.perpage = data.data.per_page;
				this.next_page = this.start_page + 1;
				this.end_page = data.data.last_page;
				this.total_data = data.data.total;
				this.to_page = data.data.to;
				// Must return an array of items or an empty array if an error occurred
				return items || [];
			});
		},
		resetSearch() {
			this.search_input = "";
			this.$refs.refReasonsList.refresh();
		},
		editReason(item) {
			this.selectedReason = item;
			this.controllerCreateReason = true;
		},
		async deleteReason(item) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;

			let { ok } = await reasonsService.delete({ reason_id: item.id });
			if (ok) {
				this.showSuccessToast("El motivo ha sido eliminado con éxito!");
				this.$refs.refReasonsList.refresh();
			}
		},
		openRegisterReason() {
			this.selectedReason = null;
			this.controllerCreateReason = true;
		},
		closeCreateReason(hasChanged) {
			this.controllerCreateReason = false;

			if (hasChanged) {
				this.$refs.refReasonsList.refresh();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
td.div {
	width: 100% !important;
}
@media (max-width: 960px) {
	.column-table {
		display: flex;
		flex-direction: column;
	}
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
