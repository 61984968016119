<template>
	<b-modal
		ref="modal-create-update-reason"
		@hidden="$emit('hidden', false)"
		:title="`${reason == null ? 'Registrar' : 'Actualizar'} motivo`"
		cancel-title="Cancelar"
		size="md"
	>
		<ValidationObserver ref="form">
			<b-form-group id="input-group-3" label="Modulo:" label-for="input-3">
				<ValidationProvider v-slot="{ errors }" rules="required">
					<b-form-select
						id="input-3"
						v-model="reasonObject.module_id"
						:options="arrayModules"
						value-field="id"
						text-field="name"
						:disabled="reason != null"
						:state="errors[0] ? false : null"
					></b-form-select>
				</ValidationProvider>
			</b-form-group>

			<b-form-group id="input-group-1" label="Razón:" label-for="input-1">
				<ValidationProvider v-slot="{ errors }" rules="required">
					<b-form-input
						id="input-1"
						v-model="reasonObject.reason"
						type="text"
						required
						:state="errors[0] ? false : null"
					/>
				</ValidationProvider>
			</b-form-group>

			<template v-if="arrayReasons.length">
				<b-form-group id="input-group-4">
					<b-form-checkbox
						id="checkbox-1"
						v-model="reasonObject.isSubOption"
						name="checkbox-1"
						:disabled="reasonObject.module_id == null && reasonObject.reason == null"
					>
						Es un sub-motivo
					</b-form-checkbox>
				</b-form-group>
				<b-form-group
					v-if="reasonObject.isSubOption"
					id="input-group-3"
					label="Sub-motivo:"
					label-for="input-3"
				>
					<ValidationProvider v-slot="{ errors }" rules="required">
						<b-form-select
							id="input-3"
							v-model="reasonObject.parent_id"
							:options="arrayReasons"
							value-field="id"
							text-field="reason"
							:state="errors[0] ? false : null"
						></b-form-select>
					</ValidationProvider>
				</b-form-group>
			</template>
		</ValidationObserver>

		<template #modal-footer="{ cancel }">
			<b-button @click="cancel()" v-if="!loading" variant="secondary"> Cancelar </b-button>
			<b-button variant="primary" disabled v-else>
				<b-spinner small />
				Cargando...
			</b-button>
			<b-button variant="primary" @click="onSave()" v-if="!loading"> {{ btnSaveTitle }} </b-button>
			<b-button variant="primary" disabled v-else>
				<b-spinner small />
				Cargando...
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal.js";
import axios from "@/axios";
import reasonsService from "@/services/reasons.service";
export default {
	name: "CreateOrUpdateReason",
	props: {
		reason: Object,
	},
	mixins: [modalMixin],
	mounted() {
		this.isPreloading();
		this.getModules();
		if (this.reason) {
			this.reasonObject.module_id = this.reason.module_id;
			this.reasonObject.parent_id = this.reason.parent_id;
			this.reasonObject.reason = this.reason.reason;
			if (this.reason.parent_id) {
				this.reasonObject.isSubOption = true;
			}
		}
		this.toggleModal("modal-create-update-reason");
		this.isPreloading(false);
	},
	computed: {
		btnSaveTitle() {
			return `${this.reason == null ? "Guardar" : "Actualizar"}`;
		},
	},
	watch: {
		"reasonObject.module_id"(newVal, oldVal) {
			this.getReasonsByModule();
		},
		"reasonObject.isSubOption"(newVal, oldVal) {
			if (newVal) {
				this.reasonObject.parent_id = null;
			}
		},
	},
	data() {
		return {
			reasonObject: {
				module_id: null,
				parent_id: null,
				reason: null,
				reason_id: null,
				created_by: null,
				isSubOption: null,
			},
			arrayModules: [],
			arrayReasons: [],
			loading: false,
		};
	},
	methods: {
		async getModules() {
			const { data } = await axios.get("/api/modules/get");
			this.arrayModules = data;
		},
		async getReasonsByModule() {
			const resp = await reasonsService.getByModule(this.reasonObject.module_id);
			this.arrayReasons = resp;
		},
		async onSave() {
			if (!(await this.$refs.form.validate())) return;

			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;

			this.reasonObject.created_by = this.currentUser.user_id;

			let resp;

			if (this.reason !== null) {
				const { reason } = this.reasonObject;
				resp = await reasonsService.update({ reason_id: this.reason.id, name: reason });
			} else {
				const { module_id, parent_id, reason } = this.reasonObject;
				resp = await reasonsService.save({ module_id, parent_id, name: reason });
			}

			if (resp.ok) {
				this.showSuccessToast(
					`El motivo ha sido
					${this.reason == null ? "registrado" : "actualizado"}
					con éxito!`
				);
			}

			this.$emit("hidden", true);
		},
	},
};
</script>
